<template>
  <div>
    <v-dialog
      v-model="cameraDialog"
      persistent
      class="camera-dialog"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '598'"
      :height="$vuetify.breakpoint.xsOnly ? '100%' : '598'"
    >
      <v-card
        class="camera-stream-card"
        :class="$vuetify.breakpoint.xsOnly ? 'camera-stream-card-mobile' : ''"
      >
        <v-card-title class="card-title">
          <v-row>
            <v-col cols="12">
              <div class="d-flex justify-center align-center">
                <img
                  @click="closeCameraDialog()"
                  src="@/assets/icons/close-icon.svg"
                  height="22"
                  width="22"
                  alt="close-icon"
                  class="cursor-pointer ml-auto"
                />
              </div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text :class="['card-body', { 'no-border': showOval }]">
          <video
            ref="videoElement"
            id="video-stream"
            class="camera-stream"
            autoplay
            muted
            playsinline
          ></video>

          <camera-overlay
            v-if="showOval"
            :class="$vuetify.breakpoint.xsOnly ? 'canvas-size-card-mobile' : ''"
            :canvasWidth="$vuetify.breakpoint.xsOnly ? 300 : 516"
            :canvasHeight="$vuetify.breakpoint.xsOnly ? 400 :291"
          />
          <v-btn
            v-if="!showOval"
            :disabled="videoStream == null"
            @click="captureImage()"
            class="capture-btn"
            absolute
            bottom
            fab
          >
            <img
              src="@/assets/icons/capture-icon.svg"
              height="20"
              width="20"
              alt="capture-icon"
            />
          </v-btn>
        </v-card-text>
        <v-card-actions class="card-footer">
          <span :class="{ 'custom-message': showOval }">
            {{ showOval ? message : "Capture Image" }}
          </span>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { showSimpleErrorMessage } from "../../utils/showError";
import CameraOverlay from "./Canvas.vue";
import { FaceDetection } from "@mediapipe/face_detection";

export default {
  name: "Camera",
  components: { CameraOverlay },
  props: {
    showOval: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCameraOpen: false,
      isLoading: false,
      cameraDialog: false,
      imageSrc: null,
      videoElement: null,
      videoStream: null,
      screenshotFormat: "image/jpeg",
      binaryFile: null,
      faceDetection: null, // For MediaPipe face detection
      message: "Position your face in Oval", // Custom message
    };
  },
  mounted() {
    this.openCamera();
  },
  watch: {
    imageSrc(val) {
      if (val !== null) {
        this.stopCameraStream();
      }
    },
    cameraDialog(val) {
      if (val === true) {
        setTimeout(() => {
          this.videoElement = document.getElementById("video-stream");
          this.videoElement.srcObject = this.videoStream;
          if (this.showOval) {
            this.initializeFaceDetection();
          }
        }, 500);
      }
    },
  },
  methods: {
    /**
     * @Description
     * 1. this method load the video stream and open the camera
     * @param error (void)
     * @return none
     **/
    openCamera() {
      if (navigator.mediaDevices?.getUserMedia) {
        let videoConstraints = {
          facingMode: { ideal: "user" },
          frameRate: 30,
          width: 1920,
          height: 1080,
          require: ["width", "height"],
        };

        if (this.$vuetify.breakpoint.mdAndUp) {
          videoConstraints = {
            width: 1280,
            height: 720,
            require: ["width", "height"],
            facingMode: { ideal: "user" },
          };
        }
        navigator.mediaDevices
          .getUserMedia({
            video: videoConstraints,
          })
          .then((stream) => {
            this.cameraDialog = true;
            this.videoStream = stream;
          })
          .catch(() => {
            this.closeCameraDialog();
            showSimpleErrorMessage(
              "Your browser don't have camera support or there is an errors."
            );
          });
      }
    },
    /**
     * @description
     * 1.This method sets up face detection using the MediaPipe library,
     *   configures options, and processes video frames continuously
     */
    initializeFaceDetection() {
      this.faceDetection = new FaceDetection({
        locateFile: (file) =>
          `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/${file}`,
      });
      // Lower confidence threshold for mobile to improve detection
      const minConfidence = this.$vuetify.breakpoint.xsOnly ? 0.3 : 0.6;
      
      this.faceDetection.setOptions({
        model: "short",
        minDetectionConfidence: minConfidence,
      });

      const videoElement = this.$refs.videoElement;

      this.faceDetection.onResults(this.onFaceResults);

      videoElement.onloadedmetadata = () => {
        const processFrame = async () => {
          await this.faceDetection.send({ image: videoElement });
          requestAnimationFrame(processFrame);
        };
        processFrame();
      };
    },
    /**
     * @description
     * 1. This method processes face detection data, compares facial
     *    landmark positions to target coordinates, and provides feedback
     *    or captures an image based on the face's alignment.
     */
    onFaceResults(results) {
      const canvas = document.createElement("canvas");
      // Adjust canvas dimensions for better mobile detection
      canvas.width = this.$vuetify.breakpoint.xsOnly ? 300 : 300;
      canvas.height = this.$vuetify.breakpoint.xsOnly ? 400 : 150;
      
      const canvasCtx = canvas.getContext("2d");     
      // Clear canvas and draw video frame
      canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
      canvasCtx.drawImage(
        this.$refs.videoElement,
        0,
        0,
        canvas.width,
        canvas.height
      );
      if (results.detections.length === 0) {
        this.message = "No face found";
        return;
      }
       // Only draw facial points and oval for mobile devices
      const isMobile = this.$vuetify.breakpoint.xsOnly;

      const targetCoordinates = isMobile ? {
        nose: { x: 150, y: 200 },      // Adjusted for mobile
        leftEye: { x: 170, y: 180 },   // Adjusted for mobile
        rightEye: { x: 130, y: 180 }   // Adjusted for mobile
      } : {
        nose: { x: 147, y: 93 },
        leftEye: { x: 166, y: 72 },
        rightEye: { x: 128, y: 74 }
      };

     // More lenient thresholds for mobile
      const thresholdExact = this.$vuetify.breakpoint.xsOnly ? 15 : 5;
      const thresholdClose = this.$vuetify.breakpoint.xsOnly ? 20 : 7;
      // Loop through each detection result
      for (const detection of results.detections) {
        const boundingBox = detection.boundingBox;
        canvasCtx.beginPath();
        canvasCtx.rect(
          boundingBox.xMin * canvas.width,
          boundingBox.yMin * canvas.height,
          boundingBox.width * canvas.width,
          boundingBox.height * canvas.height
        );
        canvasCtx.lineWidth = 3;
        canvasCtx.strokeStyle = "red";
        canvasCtx.stroke();
        // Extract landmarks
        const landmarks = detection.landmarks;
        if (landmarks) {
          // Get landmark positions
          const nose = landmarks[2];
          const noseX = nose.x * canvas.width;
          const noseY = nose.y * canvas.height;
          const leftEye = landmarks[1];
          const leftEyeX = leftEye.x * canvas.width;
          const leftEyeY = leftEye.y * canvas.height;
          const rightEye = landmarks[0];
          const rightEyeX = rightEye.x * canvas.width;
          const rightEyeY = rightEye.y * canvas.height;

          // Calculate distances to target positions
          const noseDistance = Math.sqrt(
            Math.pow(noseX - targetCoordinates.nose.x, 2) +
            Math.pow(noseY - targetCoordinates.nose.y, 2)
          );
          const leftEyeDistance = Math.sqrt(
            Math.pow(leftEyeX - targetCoordinates.leftEye.x, 2) +
            Math.pow(leftEyeY - targetCoordinates.leftEye.y, 2)
          );
          const rightEyeDistance = Math.sqrt(
            Math.pow(rightEyeX - targetCoordinates.rightEye.x, 2) +
            Math.pow(rightEyeY - targetCoordinates.rightEye.y, 2)
          );

          // Check if face is within exact or close threshold
          const isExactPosition =
            noseDistance < thresholdExact &&
            leftEyeDistance < thresholdExact &&
            rightEyeDistance < thresholdExact;

          const isClosePosition =
            noseDistance < thresholdClose &&
            leftEyeDistance < thresholdClose &&
            rightEyeDistance < thresholdClose;

          if (isExactPosition && !this.imageSrc) {
            this.message = "Keep Steady";
            // Display "Keep Steady" for 500ms, then capture image
            setTimeout(() => {
              this.captureImage();
            }, 500);
          } else if (isClosePosition) {
            this.message = "Keep Close";
          } else {
            this.message = "Adjust your face in Oval";
          }
        }
      }
    },

    /**
     * @description
     * 1. This method draws image on canvas to show proof preview
     */
    async captureImage() {
      try {
        const video = this.$refs.videoElement;
        const canvas = document.createElement("canvas");

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const ctx1 = canvas.getContext("2d");
        ctx1.drawImage(video, 0, 0, canvas.width, canvas.height);
        this.imageSrc = canvas.toDataURL(this.screenshotFormat);

        this.binaryFile = await this.imageBase64ToImageBlob(
          this.imageSrc,
          this.screenshotFormat
        );
        let data = {
          base64Image: this.imageSrc,
          file: this.binaryFile,
        };
        this.$emit("attached-image", data);
        this.closeCameraDialog();
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * @description
     * it is used to convert base64 file to blob file
     */
    imageBase64ToImageBlob(base64, type) {
      // Remove the data URL prefix (e.g., "data:image/png;base64,")
      const base64WithoutPrefix = base64.replace(/^data:[^;]+;base64,/, "");
      // Convert base64 to a binary array
      const binaryData = atob(base64WithoutPrefix);
      const arrayBuffer = new ArrayBuffer(binaryData.length);
      const uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < binaryData.length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i);
      }
      // Create a Blob from the binary data
      return new Blob([uint8Array], { type: type });
    },
    /**
     * Stops the camera stream by removing the video source from the video element
     * and stopping all tracks in the video stream. Sets the video stream to null.
     *
     * @method stopCameraStream
     */
    stopCameraStream() {
      const videoElement = this.$refs.videoElement;
      if (videoElement) {
        videoElement.srcObject = null;
      }
      this.videoStream?.getTracks().forEach((track) => track.stop());
      this.videoStream = null;
    },
    /**
     * Reloads the current page, effectively simulating a "go back" action.
     *
     * @method goBack
     */
    goBack() {
      location.reload();
    },

    /**
     * Closes the camera dialog by stopping the camera stream and setting the camera dialog
     * visibility to false. Emits a "close-camera-dialog" event.
     *
     * @method closeCameraDialog
     */
    closeCameraDialog() {
      this.stopCameraStream();
      this.cameraDialog = false;
      this.$emit("close-camera-dialog");
    },
  },
  async beforeDestroy() {
    await this.stopCameraStream();
  },
};
</script>

<style scoped>
.camera-dialog {
  width: 598px;
  height: 465;
}
.camera-stream-card {
  padding: 10px 41px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 1.4px 13px 0px rgba(0, 0, 0, 0.03),
    0px 7px 80px 0px rgba(0, 0, 0, 0.05) !important;
}
.camera-stream-card-mobile {
  padding: 10px 15px;
  height: 100%;
  width:100%;
}
.camera-stream-card:deep(.v-card__title) {
  padding: 16px 0 10px !important;
}
.camera-stream-card:deep(.v-card__text) {
  padding: 0 !important;
}
.camera-stream-card:deep(.v-card__actions) {
  padding-top: 40px !important;
}
.card-title {
  display: flex;
  color: #292b30;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}
.card-body {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 6px;
  border: 1px dashed #e3e3e3;
  background: #f7f7f7;
  padding: 0;
}
.card-footer {
  color: #292b30;
  display: flex;
  justify-content: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 30px;
}
.capture-btn {
  background: radial-gradient(50% 50% at 50% 50%, #3e7ef6 0%, #324ee0 100%);
}
.camera-stream {
  min-width: 100%;
  min-height: 100%;
  min-width: fill-available;
  min-width: -webkit-fill-available;
  min-width: -moz-fill-available;
  object-fit: cover;
  border-radius: 6px;
  transform: scaleX(-1);
}
.custom-message {
  position: absolute;
  top: 40px;
  z-index: 4;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  border-radius: 4px;
  font-size: 20px;
}
.no-border {
  border: none; /* Remove border when showOval is true */
}
.canvas-size-card-mobile {
  width: 100%;
  height: 100%;
}
</style>

<template>
  <canvas id="canvas"></canvas>
</template>
  <script>
export default {
  props: {
    canvasHeight: Number,
    canvasWidth: Number,
  },
  data() {
    return {
      allChecksPassed: false,
    };
  },
  mounted() {
    this.drawOverlay();
  },
  methods: {
    drawOverlay() {
      const canvas = document.getElementById("canvas");
      // if (this.MOBILE_DEVICE) {
      //   canvas.style.top = '50px';
      // }
      const ctx = canvas.getContext("2d");

      canvas.width = this.canvasWidth;
      canvas.height = this.canvasHeight;
      // Set the background color for the canvas
      ctx.fillStyle = "#ffffff"; // light grey background
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Create a Path2D object for the oval
      const ovalPath = new Path2D();
      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;

      let radiusX = 110; // Adjust this value for the width of the oval
      let radiusY = 150; // Adjust this value for the height of the oval
      // Calculate the target radius for the animation
      let targetRadiusX = this.allChecksPassed ? 130 : 110;
      let targetRadiusY = this.allChecksPassed ? 170 : 150;
    //   if (this.isTablet()) {
      //default desktop size
        radiusX = 85; // Adjust this value for the width of the oval
        radiusY = 115; // Adjust this value for the height of the oval
        targetRadiusX = this.allChecksPassed ? 250 : 70;
        targetRadiusY = this.allChecksPassed ? 330 : 100;
      // Adjust sizes for mobile using Vuetify's breakpoint
        if (this.$vuetify.breakpoint.xsOnly) {
          radiusX = 85;  // Smaller initial width for mobile
          radiusY = 85;  // Smaller initial height for mobile
          targetRadiusX = this.allChecksPassed ? 70 : 60;
          targetRadiusY = this.allChecksPassed ? 90 : 80;
        }
    //   } else if (this.MOBILE_DEVICE) {
    //     radiusX = 130; // Adjust this value for the width of the oval
    //     radiusY = 180; // Adjust this value for the height of the oval
    //     targetRadiusX = this.allChecksPassed ? 150 : 130;
    //     targetRadiusY = this.allChecksPassed ? 230 : 180;
    //   }
      // Animation parameters
      const animationDuration = 300; // Duration of the animation in milliseconds
      const startTime = performance.now();
      // Start the animation
      let self = this;
      const animate = () => {
        const currentTime = performance.now();
        const elapsedTime = currentTime - startTime;
        const progress = elapsedTime / animationDuration;

        if (progress < 1) {
          const currentRadiusX = radiusX + (targetRadiusX - radiusX) * progress;
          const currentRadiusY = radiusY + (targetRadiusY - radiusY) * progress;

          ovalPath.ellipse(
            centerX,
            centerY,
            currentRadiusX,
            currentRadiusY,
            0,
            0,
            2 * Math.PI
          );
          ctx.strokeStyle = self.allChecksPassed ? "#1dd153" : "#FFFFFF";
          ctx.lineWidth = 5;
          ctx.stroke(ovalPath);

          // Clear the inside of the oval shape
          ctx.globalCompositeOperation = "destination-out";
          ctx.fill(ovalPath);
          ctx.globalCompositeOperation = "source-over";

          requestAnimationFrame(animate);
        }
      };

      animate();
    },
  },
};
</script>
  <style scoped>
#canvas {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
}
</style>
  